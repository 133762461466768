.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100px;
  border: 2px dashed #84b2f8;
  border-radius: 15px;
  color: #84b2f8;
  margin-bottom: 1rem;
  transition: border-color 0.2s, box-shadow 0.2s, transform 0.2s;
  background: linear-gradient(135deg, #3b7afa 0%, #0d66b9 100%);
}

.dropzone:hover {
  border-color: #18d87e;
  box-shadow: -4px 8px 14px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
}

.drag-active {
  border-color: #c11d1d;
  animation: shake 0.5s linear infinite;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-2px); }
  20%, 40%, 60%, 80% { transform: translateX(2px); }
}
