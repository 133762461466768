:root {
  --primary-color: #4385c0;
  --light-color: #90caf9;
  --dark-color: #0d47a1;
  --background-color-normal: radial-gradient(135deg, rgb(30, 136, 229) 0%, rgb(144, 202, 249) 100%);
  --background-color-dark: #263238;
  --secondary-color: #263238;
  --text-color-dark: #ffffff;
}

body.dark-mode {
  background-color: var(--background-color-dark);
}



.App {
  margin-top: 5vh !important;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: var(--primary-color);
  padding: 30px;
  border-radius: 15px;
  box-shadow: inset 8px 8px 16px var(--dark-color), inset -8px -8px 16px var(--light-color);
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--dark-color) 100%);
}

h1 {
  margin-top: 10px;
  margin-bottom: 50px;
  color: var(--light-color);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.textacceuil {
  margin-top: 10px;
  margin-bottom: 50px;
  color: var(--light-color);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  line-height: 23px;
}
input[type="file"] {
  cursor: pointer;
}

.original-text,
.translated-text {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 15px;
  background-color: var(--primary-color);
  color: var(--light-color);
  box-shadow: inset 4px 4px 8px var(--dark-color), inset -4px -4px 8px var(--light-color);
  background: linear-gradient(135deg, rgba(44, 100, 184, 0.5) 0%, rgba(40, 69, 126, 0.5) 100%);
}

.original-text:focus,
.translated-text:focus {
  box-shadow: inset 6px 6px 12px var(--dark-color), inset -6px -6px 12px var(--light-color);
}

button.translated-textt {
  padding: 8px 16px;
  font-size: 16px;
  color: #fff;
  background-image: linear-gradient(to right, #18d87e, #049506);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;
}

button.translated-textt:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

button.translated-textt:disabled {
  background-image: linear-gradient(to right, #ccc, #ccc);
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}
.App.dark-mode {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

.App.dark-mode h1 {
  color: var(--text-color-dark);
}
.App.dark-mode h3 {
  color: var(--text-color-dark);
}
.App.dark-mode .original-text,
.App.dark-mode .translated-text {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  padding: 10px 20px;
  border-radius: 15px;
  box-shadow: 4px 4px 8px var(--dark-color), -4px -4px 8px var(--light-color);
}

nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav li {
  margin: 0 10px;
}

nav a {
  display: block;
  padding: 10px;
  color: var(--light-color);
  text-decoration: none;
  font-size: 20px;
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;
}

nav a:hover {
  background-color: var(--secondary-color);
  box-shadow: none;
  transform: translateY(-2px);
}

nav a.active {
  background-color: var(--secondary-color);
  box-shadow: none;
  transform: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* a {
  color: var(--text-color);
  text-decoration: none;;
} */

.rightt{
    display: flex;
    justify-content: flex-end;
    position: fixed;
    z-index: 1;
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

/* <li> */
.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: flex-end ;
color: white;
  

}

.leftttttt{
    display: flex;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg { 
  fill: var(--text-color);
  width: 20px;
  height: 20px;
}


.Navbar3{
    width: 100%;
}

.navi18{
  cursor: pointer;
}

h4 {
  margin-top: 10px;
  margin-bottom: 50px;
  color: var(--light-color);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.rgbhex{
  margin-top: 5px;
  margin-bottom: 35px;
  color: var(--light-color);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}