.image-editor {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  margin-bottom: 10px;
}

.dropzone p {
  font-size: 16px;
}

button {
  margin: 5px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.dropzone {
  width: 100%;
  max-width: 800px;
  height: 100px;
  border: 2px dashed #333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.dropzone:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.canvas-container {
  border: 1px solid #888;
  width: 800px;
  height: 400px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #fff;
  position: relative;
}

#canvas {
  z-index: 1;
}

.tools {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tools button {
  border: none;
  background-color: #2196f3;
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s;
}

.tools button:hover {
  background-color: #0d8af2;
}

.tools button:active {
  background-color: #0b7cd6;
}

.tools select {
  border: 1px solid #888;
  padding: 6px 12px;
  font-size: 14px;
  margin-left: 5px;
}
