.translated-textt  {

margin-bottom: 1rem;
background-image: linear-gradient(to right, #18d87e, #049506);
color: #fff;
padding: 8px 16px;
border: none;
border-radius: 4px;
cursor: pointer;
transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;


} 

.translated-textt button:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
  
  .translated-textt button:disabled {
    background-image: linear-gradient(to right, #ccc, #ccc);
    cursor: not-allowed;
    box-shadow: none;
    transform: none;
  }
