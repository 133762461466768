.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .textacceuil {
    text-align: center;
  }
  
  .tools-preview-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    margin-top: 32px;
  }
  
  .tool-preview {
    background-color: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 210px;
    height: 210px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: box-shadow 0.3s ease;
  }
  
  .tool-preview:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .tool-preview h4 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  
  .tool-preview p {
    font-size: 14px;
    text-align: center;
    color: #757575;
    margin: 0;
  }
  .tool-image {
    max-width: 50%;
    height: auto;
    margin-bottom: 5px;
    margin-top: 25px;
  }
  .text-image-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    gap: 16px;
  }
  
  .left-text {
    max-width: 50%;
  }
    .right-image img {
        max-width: 110%;
        height: auto;
    }
