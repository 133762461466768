.image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100px;
  border: 2px dashed #84b2f8;
  border-radius: 15px;
  color: #84b2f8;
  margin-bottom: 1rem;
  transition: border-color 0.2s, box-shadow 0.2s, transform 0.2s;
  background: linear-gradient(135deg, #3b7afa 0%, #0d66b9 100%);
}

.dropzone:hover {
  border-color: #18d87e;
  box-shadow: -4px 8px 14px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
}

.drag-active {
  border-color: #c11d1d;
  animation: shake 0.5s linear infinite;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-2px); }
  20%, 40%, 60%, 80% { transform: translateX(2px); }
}

.image-uploader p {
  margin: 0;
}

.selected-file {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected-file p {
  margin-bottom: 1rem;
}

.image-preview {
  max-width: 300px;
  max-height: 200px;
  object-fit: contain;
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: box-shadow 0.2s, transform 0.2s;
}

.image-preview:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-4px);
}

.image-uploader button {
  margin-bottom: 1rem;
  background-image: linear-gradient(to right, #18d87e, #049506);
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;
}

.image-uploader button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.image-uploader button:disabled {
  background-image: linear-gradient(to right, #ccc, #ccc);
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}